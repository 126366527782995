import { TProductCategory } from '@lib/core/products/types';
import { IThirdPartyNewsletterTexts, ThirdPartyConnectModalScenario } from '@lib/core/service/types/interface';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { PRODUCT_CATEGORY_WINE } from '@lib/tools/shared/helpers/consts';

import BeerPlacesIcon from '@components/web/src/assets/png/beer/place_saved_beer_spark.png';
import BeerWishlistIcon from '@components/web/src/assets/png/beer/product_saved_beer_spark.png';
import BeerRate4Icon from '@components/web/src/assets/png/beer/rated_4_beer_spark.png';
import BeerRate5Icon from '@components/web/src/assets/png/beer/rated_5_beer_spark.png';
import CoffeePlacesIcon from '@components/web/src/assets/png/coffee/place_saved_coffee_spark.png';
import CoffeeWishlistIcon from '@components/web/src/assets/png/coffee/product_saved_coffee_spark.png';
import CoffeeRate4Icon from '@components/web/src/assets/png/coffee/rated_4_coffee_spark.png';
import CoffeeRate5Icon from '@components/web/src/assets/png/coffee/rated_5_coffee_spark.png';
import WinePlacesIcon from '@components/web/src/assets/png/wine/place_saved_wine_spark.png';
import WineWishlistIcon from '@components/web/src/assets/png/wine/product_saved_wine_spark.png';
import WineRate4Icon from '@components/web/src/assets/png/wine/rated_4_wine_spark.png';
import WineRate5Icon from '@components/web/src/assets/png/wine/rated_5_wine_spark.png';
import Button from '@components/web/src/atoms/Buttons/Button';
import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';
import * as S from '@components/web/src/templates/Modals/ThirdPartyConnectModal/styles';

interface IThirdPartyConnectModalProps {
  retailerLogo: string;
  isOpen: boolean;
  productCategory: TProductCategory;
  productName: string;
  scenario:
    | ThirdPartyConnectModalScenario.LOCATION
    | ThirdPartyConnectModalScenario.RATE5
    | ThirdPartyConnectModalScenario.RATE4
    | ThirdPartyConnectModalScenario.WISHLIST;
  hideModal: () => void;
  handleBtnClick: (acceptance: boolean) => void;
  thirdPartyNewsletterTexts: IThirdPartyNewsletterTexts;
  retailerName: string;
}

const Icons = {
  beer: {
    [ThirdPartyConnectModalScenario.LOCATION]: BeerPlacesIcon,
    [ThirdPartyConnectModalScenario.RATE4]: BeerRate4Icon,
    [ThirdPartyConnectModalScenario.RATE5]: BeerRate5Icon,
    [ThirdPartyConnectModalScenario.WISHLIST]: BeerWishlistIcon,
  },
  coffee: {
    [ThirdPartyConnectModalScenario.LOCATION]: CoffeePlacesIcon,
    [ThirdPartyConnectModalScenario.RATE4]: CoffeeRate4Icon,
    [ThirdPartyConnectModalScenario.RATE5]: CoffeeRate5Icon,
    [ThirdPartyConnectModalScenario.WISHLIST]: CoffeeWishlistIcon,
  },
  wine: {
    [ThirdPartyConnectModalScenario.LOCATION]: WinePlacesIcon,
    [ThirdPartyConnectModalScenario.RATE4]: WineRate4Icon,
    [ThirdPartyConnectModalScenario.RATE5]: WineRate5Icon,
    [ThirdPartyConnectModalScenario.WISHLIST]: WineWishlistIcon,
  },
};

const ThirdPartyConnectModal = ({
  retailerLogo,
  isOpen,
  hideModal,
  handleBtnClick,
  scenario,
  productCategory,
  productName,
  thirdPartyNewsletterTexts,
  retailerName,
}: IThirdPartyConnectModalProps) => {
  const { productSaveTitle, rateProductTitle, locationTitle } = localeCommon.thirdPartyConnect;
  const { titleText, acceptBtnText, rejectBtnText, descriptionText } = thirdPartyNewsletterTexts;
  const titleTexts = {
    [ThirdPartyConnectModalScenario.LOCATION]: locationTitle,
    [ThirdPartyConnectModalScenario.WISHLIST]: productSaveTitle,
    [ThirdPartyConnectModalScenario.RATE4]: rateProductTitle,
    [ThirdPartyConnectModalScenario.RATE5]: rateProductTitle,
  };

  const categoryIcons = Icons[productCategory] || Icons[PRODUCT_CATEGORY_WINE];

  return (
    <ModalWrapper maxContentHeight hideModal={hideModal} isOpen={isOpen}>
      <S.ThirdPartyConnectModalWrapper isFullWidth align="center" direction="column" gap={0}>
        <S.Icon align="center" justify="flex-end">
          <CloseButton handleClick={hideModal} />
        </S.Icon>
        <Flexbox isFullWidth align="center" direction="column" gap={20}>
          <Image alt="icon" height="89px" objectFit="contain" src={categoryIcons[scenario]} width="83px" />
          <Flexbox align="center" direction="column" gap={8}>
            <Image alt="retailer" height="100px" objectFit="contain" src={retailerLogo} width="250px" />
            <Text
              fontFamily="Fraunces"
              localeIndex={{ productName, retailerName }}
              size="h6"
              text={titleTexts[scenario]}
              textAlign="center"
              weight="semibold"
            />
          </Flexbox>
          <Text localeOptions={{ retailerName }} size="body1" text={titleText} />

          <Flexbox isFullWidth direction="column" gap={8}>
            <Button
              isFullWidth
              fontSize="subtitle2"
              handleClick={() => handleBtnClick(true)}
              size="md"
              text={acceptBtnText}
            />
            <Button
              isFullWidth
              handleClick={() => handleBtnClick(false)}
              size="md"
              text={rejectBtnText}
              variant="link"
            />
          </Flexbox>
          <Text size="body3" text={descriptionText} />
        </Flexbox>
      </S.ThirdPartyConnectModalWrapper>
    </ModalWrapper>
  );
};

export default ThirdPartyConnectModal;
