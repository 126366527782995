import { CSSProperties, FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import * as S from '@components/web/src/atoms/Buttons/BackButton/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ArrowIcon from '@components/web/src/shared/Icons/ArrowIcon';

export type TBackButtonVariant = 'short' | 'long';

interface IProps {
  customArrowColor?: string;
  style?: CSSProperties;
  text?: ILocaleText;
  variant?: TBackButtonVariant;
  handleClick: () => void;
}

// TODO: After converting all components that uses this to STYLED
// We remove the STYLES

const BackButton: FC<IProps> = ({ handleClick, variant = 'short', customArrowColor, text, style, ...rest }) => {
  const { back, goBackBtn } = localeCommon.commonMessages;

  const isVariantLong = variant === 'long';
  const arrowSize = isVariantLong ? 'long' : 'short';
  const btnText = text || (isVariantLong ? goBackBtn : back);
  const btnTextSize = isVariantLong ? 'body1' : 'body2';

  return (
    <S.BackButtonContainer $variant={variant} style={style} type="button" onClick={handleClick} {...rest}>
      <ArrowIcon fillColor={customArrowColor} size={arrowSize} />
      <Text color={STATIC_COLORS.base.black} size={btnTextSize} text={btnText} />
    </S.BackButtonContainer>
  );
};

export default BackButton;
