import { FC, useEffect, useState } from 'react';

import { IThirdPartyNewsletterTexts } from '@lib/core/service/types/interface';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/ThirdPartyNewsletter/styles';

interface IProps {
  retailerName: string;
  withMarginTop?: boolean;
  handleThirdPartyAcceptance: (value: boolean) => void;
  thirdPartyNewsletterTexts: IThirdPartyNewsletterTexts;
}

const ThirdPartyNewsletterSticky: FC<IProps> = ({
  handleThirdPartyAcceptance,
  withMarginTop,
  thirdPartyNewsletterTexts,
  retailerName,
}) => {
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const [isShowReadMoreDescription, setIsShowReadMoreDescription] = useState(false);
  const { titleText, acceptBtnText, rejectBtnText, descriptionText } = thirdPartyNewsletterTexts || {};
  const { ctaReadMoreBtn, ctaReadLessBtn } = localeCommon.commonMessages;

  // This useEffect listens for window resize event and dynamically increase the top value of
  // ThirdPartyNewsletterContainer isVariantSticky

  useEffect(() => {
    const rootElement = document.getElementById('root-element');

    const updateTop = () => {
      const baseHeight = 915; // min-height in px that we apply this effect
      const currentHeight = rootElement.clientHeight;

      if (currentHeight > baseHeight) {
        const topIncrement = (currentHeight - baseHeight) / 10;
        setDynamicHeight(topIncrement);
      } else {
        setDynamicHeight(0);
      }
    };

    updateTop();

    window.addEventListener('resize', updateTop);
    return () => {
      window.removeEventListener('resize', updateTop);
    };
  }, []);

  return (
    <>
      <S.ThirdPartyNewsletterContainer
        $isVariantSticky
        $dynamicHeight={dynamicHeight}
        $withMarginTop={withMarginTop}
        align="center"
        justify="center"
      >
        <S.ThirdPartyNewsletterWrapper $isVariantSticky align="center" direction="column" gap={16} justify="center">
          <S.TitleTextWrapper $isVariantSticky>
            <Text
              color={STATIC_COLORS.base.white}
              localeOptions={{ retailerName }}
              size="body1"
              text={titleText}
              weight="normal"
            />

            <S.MoreText
              color="#E9C46A"
              handleClick={() => setIsShowReadMoreDescription(prevState => !prevState)}
              size="body1"
              text={isShowReadMoreDescription ? ctaReadLessBtn : ctaReadMoreBtn}
              weight="normal"
            />
          </S.TitleTextWrapper>

          {isShowReadMoreDescription && <Text color={STATIC_COLORS.base.white} size="body3" text={descriptionText} />}

          <S.BtnWrapper $isVariantSticky>
            <S.AcceptBtn
              $isVariantSticky
              fontSize="body1"
              size="sm"
              text={acceptBtnText}
              textWeight="medium"
              variant="light"
              onClick={() => handleThirdPartyAcceptance(true)}
            />
            <S.RejectBtn
              $isVariantSticky
              fontSize="body1"
              size="sm"
              text={rejectBtnText}
              textWeight="medium"
              variant="primary"
              onClick={() => handleThirdPartyAcceptance(false)}
            />
          </S.BtnWrapper>
        </S.ThirdPartyNewsletterWrapper>
      </S.ThirdPartyNewsletterContainer>
    </>
  );
};

export default ThirdPartyNewsletterSticky;
