import styled from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';

export const KioskProductPageContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${STATIC_COLORS.base.white};
`;

export const StyledBackButton = styled(BackButton)`
  padding: 8px 32px;
`;

export const ProductContent = styled(Flexbox)`
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 44px 32px 100px;
  border-radius: 40px 40px 0px 0px;
  box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 0.17);
  margin-top: 16px;
  background-color: var(--color-secondary-100);
`;

export const MoreAboutProduct = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;
`;

export const RedirectQrSection = styled.div`
  display: flex;
  align-items: center;
  min-height: 273px;
  gap: 12px;
  padding: 8px;
  border-radius: 50px 0px 0px 50px;
  border-top: 1px solid ${STATIC_COLORS.teal[200]};
  border-bottom: 1px solid ${STATIC_COLORS.teal[200]};
  border-left: 1px solid ${STATIC_COLORS.teal[200]};
  background: ${STATIC_COLORS.teal[50]};
  margin-right: -32px;
`;

export const QrWrapper = styled.div<{ $qrBackground: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 237px;
  height: 224px;
  .qr-code {
    z-index: 2;
  }
`;

export const QrBackgroundImage = styled(Image)`
  position: absolute;
  width: 237px;
  height: 224px;
`;

export const QrText = styled(Text)`
  width: 190px;
`;

export const ChefRecommendedPairingsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 16px 32px;
  border-radius: 10px;
  background: ${STATIC_COLORS.base.white};
  box-shadow: ${STATIC_SHADOWS.xl};
`;

export const ChefRecommendedPairingsImage = styled(Image)`
  height: 178px;
  min-width: 307px;
  max-width: 307px;
`;

export const ChefRecommendedPairingsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;
`;

export const ChefRecommendedPairingsCta = styled.button`
  display: flex;
  width: 149px;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  border: none;
  background: transparent;
`;

export const ChefRecommendedPairingsCtaText = styled(Text)`
  cursor: pointer;
`;

export const ProductTechnicalDescriptionWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 16px 32px;
  border-radius: 10px;
  background: ${STATIC_COLORS.base.white};
  box-shadow: ${STATIC_SHADOWS.xl};
`;

export const TechnicalDescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 636px;
  padding: 38px 0;
  gap: 32px;
  z-index: 2;

  button {
    width: 492px;
  }
`;

export const TechnicalDescriptionImageOne = styled(Image)`
  position: absolute;
  left: 32px;
  top: 50%;
  margin-top: -100px;
`;

export const TechnicalDescriptionImageTwo = styled(Image)`
  position: absolute;
  right: 32px;
  top: 50%;
  margin-top: -100px;
`;
