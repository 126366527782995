import { apiUrlGetRecipes } from '@lib/core/productAttributes/slices/urls';
import { TProductCategory } from '@lib/core/products/types';
import request from '@lib/core/service/requests/request';
import { PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';

export const getRecipesData = (recipeType: string, productCharacterId: string, productCategory: TProductCategory) =>
  request(apiUrlGetRecipes(), {
    params: {
      [PRODUCT_CATEGORY_QUERY]: productCategory,
      character: productCharacterId,
      page_size: 1,
      random: true,
      recipe_type: recipeType,
    },
  })
    .then(data => data.results)
    .catch(error => {
      throw new Error(error);
    });
