import { css, styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const SublistItemContainer = styled(Flexbox)<{
  $isActive?: boolean;
  $isRatingItem?: boolean;
}>`
  padding: 16px 16px 16px 24px;
  border-top: 1px solid ${STATIC_COLORS.warmGray['300']};
  margin: 0px;
  cursor: pointer;
  background: ${STATIC_COLORS.base.white} !important;
  height: 60px;
  ${({ $isRatingItem }) =>
    $isRatingItem &&
    css`
      border: none !important;
      padding: 0px !important;
    `}
  ${({ theme, $isActive }) =>
    theme?.isKioskRootElementType &&
    css`
      gap: 22px;
      border: 1px solid ${STATIC_COLORS.warmGray['400']};
      border-radius: 99px;
      ${$isActive &&
      css`
        background-color: ${STATIC_COLORS.teal['25']} !important;
        border: 1px solid ${STATIC_COLORS.teal['200']};
      `}
    `}
    img {
    width: 48px;
    height: 48px;
  }
`;
