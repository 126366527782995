import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { getTastePathProductsData } from '@lib/core/products/utils';
import { selectRetailerMaxLengthTastePathPerCategory } from '@lib/core/retailers/selectors/retailer';
import { STYLE_QUERY } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { selectProductFeedbackData } from '@lib/core/users/selectors/productFeedback';
import { TProductFeedback } from '@lib/core/users/slices/productFeedback';
import { TASTE_PATH_PRODUCT_STYLES, TASTE_PATH_PRODUCT_STYLES_IDS } from '@lib/tools/shared/helpers/consts';
import { IState, ITastePathData } from '@lib/tools/tastePathProducts/types/types';

const initialState: IState = {
  currentTastePathCategoryId: '',
  isTastePathProductsLoading: false,
  nextTastePathCategoryId: '',
  requestError: '',
  tastePathData: null,
};

const getNextTastePathCategoryId = (tastePathData, currentTastePathCategoryId) => {
  const categories = Object.keys(tastePathData);
  let currentIndex = categories.indexOf(currentTastePathCategoryId);
  let lastNonSkippedCategory = currentTastePathCategoryId;

  if (categories.every(category => tastePathData[category].shouldSkipCategory)) return lastNonSkippedCategory;

  do {
    currentIndex = (currentIndex + 1) % categories.length;
    const nextCategory = categories[currentIndex];
    if (!tastePathData[nextCategory].shouldSkipCategory) {
      lastNonSkippedCategory = nextCategory;
      return nextCategory;
    }
  } while (currentIndex !== categories.indexOf(currentTastePathCategoryId));

  return lastNonSkippedCategory;
};

export const createTastePathData = ({
  products,
  productCategory,
  feedbackData,
}: {
  products: TProductInstance[];
  productCategory: TProductCategory;
  feedbackData: TProductFeedback[];
}): Record<string, ITastePathData> => {
  const data = {};
  const stylesSortedByOrder = Object.keys(TASTE_PATH_PRODUCT_STYLES[productCategory]).sort(
    (a, b) => TASTE_PATH_PRODUCT_STYLES[productCategory][a].order - TASTE_PATH_PRODUCT_STYLES[productCategory][b].order,
  );

  stylesSortedByOrder.forEach(style => {
    const filteredProducts = products.filter(product => product.character.type.identifier === style);

    const shouldSkipCategory =
      feedbackData.filter(product => product?.product?.character?.type?.identifier === style).length >=
      filteredProducts.length;

    data[style] = {
      products: filteredProducts,
      shouldSkipCategory,
    };
  });

  return data;
};

export const fetchTastePathProducts = createTypedAsyncThunk('tastePath/fetchTastePathProducts', async (_, thunkAPI) => {
  try {
    const { getState } = thunkAPI;
    const state = getState();
    const productCategory = selectServiceProductCategory(state);
    const feedbackData = selectProductFeedbackData(state);
    const retailerMaxLengthTastePathPerCategory = selectRetailerMaxLengthTastePathPerCategory(state);

    const productPromises = TASTE_PATH_PRODUCT_STYLES_IDS[productCategory].map(style =>
      getTastePathProductsData({
        [STYLE_QUERY]: style,
        limit: retailerMaxLengthTastePathPerCategory,
      }),
    );
    const response = await Promise.all(productPromises);
    const products = response.map(tastePathData => tastePathData.results).flat();

    const tastePathData = createTastePathData({ feedbackData, productCategory, products });

    const currentTastePathCategoryId = Object.keys(tastePathData).find(
      categoryId => tastePathData[categoryId].products.length > 0,
    );

    const nextTastePathCategoryId = getNextTastePathCategoryId(tastePathData, currentTastePathCategoryId);

    return { currentTastePathCategoryId, nextTastePathCategoryId, tastePathData };
  } catch (error: any) {
    throw new Error(error);
  }
});

const tastePathProductsSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchTastePathProducts.pending, state => {
        state.isTastePathProductsLoading = true;
        state.requestError = '';
      })
      .addCase(fetchTastePathProducts.fulfilled, (state, { payload }) => {
        const { tastePathData, currentTastePathCategoryId, nextTastePathCategoryId } = payload;

        state.isTastePathProductsLoading = false;
        state.tastePathData = tastePathData;
        state.currentTastePathCategoryId = currentTastePathCategoryId;
        state.nextTastePathCategoryId = nextTastePathCategoryId;
        state.requestError = '';
      })
      .addCase(fetchTastePathProducts.rejected, (state, action: any) => {
        state.isTastePathProductsLoading = false;
        state.requestError = action.payload?.errorMessage || action.error?.message;
      });
  },
  initialState,
  name: 'tastePath',
  reducers: {
    resetTastePathData() {
      return initialState;
    },
    setNextTastePathCategory(state) {
      const newCurrentTastePathCategoryCategory =
        state.tastePathData && getNextTastePathCategoryId(state.tastePathData, state.currentTastePathCategoryId);

      const nextCategory =
        state.tastePathData && getNextTastePathCategoryId(state.tastePathData, newCurrentTastePathCategoryCategory);

      state.currentTastePathCategoryId = newCurrentTastePathCategoryCategory;
      state.nextTastePathCategoryId = nextCategory;
    },
    setShouldSkipCategory(
      state,
      action: PayloadAction<{
        currentTastePathCategoryId: string;
        shouldChangeTastePathCategory: boolean;
      }>,
    ) {
      const { currentTastePathCategoryId, shouldChangeTastePathCategory } = action.payload;

      state.tastePathData[currentTastePathCategoryId].shouldSkipCategory = shouldChangeTastePathCategory;
    },
  },
});

export const { setShouldSkipCategory, setNextTastePathCategory, resetTastePathData } = tastePathProductsSlice.actions;
export default tastePathProductsSlice.reducer;
