import { localeCommon } from '@lib/tools/locale/source/web/common';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Loading/LowSpeedWarning/styles';

export const LowSpeedWarning = () => {
  const { lowSpeedWarningTitle, lowSpeedWarningDescription } = localeCommon.lowSpeedWarning;

  return (
    <S.LowSpeedWarningContainer>
      <Text color={STATIC_COLORS.base.white} size="subtitle2" text={lowSpeedWarningTitle} weight="bold" />
      <Text color={STATIC_COLORS.base.white} size="body1" text={lowSpeedWarningDescription} />
    </S.LowSpeedWarningContainer>
  );
};
