import styled, { css } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { PRODUCT_CARD_WIDTH } from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/styles';

export const ProductCardSkeletonContainer = styled.div<{ $isResponsive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ $isResponsive }) => ($isResponsive ? `100%` : PRODUCT_CARD_WIDTH.WEB)};
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.lg};

  ${({ theme, $isResponsive }) =>
    theme?.isKioskRootElementType &&
    css`
      max-width: ${$isResponsive ? '100%' : PRODUCT_CARD_WIDTH.KIOSK};
      width: ${$isResponsive ? '100%' : PRODUCT_CARD_WIDTH.KIOSK};
    `}

  .cta {
    width: 30%;
    min-height: 18px;
    margin: 10px auto 14px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
  min-height: 216px;
  padding: 0 0 12px 16px;
  background: ${STATIC_GRADIENTS.warmGradientReversed()};
  border-radius: 8px 8px 0 0;
  animation: skeleton-loading-animation-keyframes 1s linear infinite alternate;

  .circle {
    width: 32px;
    min-height: 32px;
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  width: 100%;
  padding: 16px;

  .character {
    width: 65%;
    min-height: 16px;
  }

  .name {
    width: 100%;
    min-height: 60px;
  }

  .producer {
    width: 45%;
    min-height: 18px;
  }

  .description {
    width: 100%;
    min-height: 32px;
    margin: 8px 0;
  }

  .format-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .format {
      width: 45%;
      min-height: 16px;
    }

    .price {
      width: 35%;
      min-height: 24px;
    }
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .format {
    width: 45%;
    min-height: 16px;
  }

  .price {
    width: 35%;
    min-height: 24px;
  }
`;
