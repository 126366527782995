import { FC, ReactElement, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { ModalTypes } from '@lib/core/service/consts';
import { selectIsScannerLoading } from '@lib/core/service/selectors/technical';
import { scanCode } from '@lib/core/service/slices/technical/scanner';
import { store } from '@lib/core/service/store';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { closeFidelityCardModal, firstTimeUserSimulationScan } from '@lib/core/users/slices/fidelityCard';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { FidelityModalsTypes } from '@lib/tools/shared/helpers/consts';
import { useDisclosure } from '@lib/tools/views/hooks';

import EventsModal from '@components/web/src/templates/Modals/Events/EventsModal/EventsModal';

type IProps = {
  children: ReactElement;
};

const ScannerHOC: FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();

  const scanOnCooldown = useRef(false);
  const scanCooldownTimer = useRef(null);
  const { activeFidelityModalCode } = useFidelityCard();
  const [isOpen, { open, close }] = useDisclosure({ defaultIsOpen: false });

  const {
    fidelityCard: { notFoundFidelityCard, foundFidelityCard, alreadyAssociatedFidelityCard, disconnectedFidelityCard },
  } = localeKiosk;
  const {
    commonMessages: { close: closeButtonText },
  } = localeCommon;

  const fidelityModalsData = {
    [FidelityModalsTypes.SuccessWithoutCharacter]: {
      description: foundFidelityCard,
      icon: 'Occasion',
    },
    [FidelityModalsTypes.SuccessWithCharacter]: {
      description: alreadyAssociatedFidelityCard,
      icon: 'Occasion',
    },
    [FidelityModalsTypes.CardDisconnected]: {
      description: disconnectedFidelityCard,
      icon: 'Curious',
    },
    [FidelityModalsTypes.NotFoundCard]: {
      buttonText: closeButtonText,
      description: notFoundFidelityCard,
      icon: 'Question',
      withButton: true,
    },
  };

  const modalData = fidelityModalsData[activeFidelityModalCode] || {};

  let eanCode = '';

  const scannerListener = (e: KeyboardEvent) => {
    /**
     * Conditions for a valid EAN:
     * 1. Must consist of numbers
     * UPDATED: now it can be a string of numbers
     * 2. Must be between 6 and 13 characters
     * 3. Scan ends with an 'Enter' keystroke.
     * 4. Target input mustn't be a field
     */

    const textKey = e.key || String.fromCharCode(e.keyCode);

    const { target } = e;
    const textTarget = (target as HTMLElement)?.tagName;
    const isScanningLoading = selectIsScannerLoading(store.getState());

    const resetScan = () => {
      eanCode = '';
      scanOnCooldown.current = true;
      scanCooldownTimer.current = setTimeout(() => {
        scanOnCooldown.current = false;
      }, 2000);
    };

    const isTestFirstTimeUserFidelityCard = eanCode === '421034841177';

    if (textTarget !== 'INPUT') {
      if (textKey.length === 1) {
        eanCode += textKey;
      } else if (eanCode.length >= 6 && eanCode.length <= 13 && textKey === 'Enter') {
        if (isTestFirstTimeUserFidelityCard) {
          /**
           * QA Simulation first-time user session without:
           * 1. Saving characters to the card
           * 2. Making any backend requests for the card
           */
          dispatch(firstTimeUserSimulationScan(String(eanCode)));
          resetScan();
        } else if (!isScanningLoading && !scanOnCooldown.current) {
          dispatch(scanCode(String(eanCode)));
          resetScan();
        }
      } else {
        eanCode = '';
      }
    }
  };

  const handleHideModal = () => {
    dispatch(closeFidelityCardModal());
    close();
  };

  useEffect(() => {
    let timeoutId;

    if (isOpen) {
      timeoutId = setTimeout(() => {
        handleHideModal();
      }, 5000);
    }

    return () => clearTimeout(timeoutId);
  }, [isOpen, close]);

  useEffect(() => {
    if (activeFidelityModalCode) {
      open();
    }
  }, [activeFidelityModalCode]);

  useEffect(() => {
    return () => {
      clearTimeout(scanCooldownTimer.current);
    };
  }, []);

  useEffect(() => {
    /**
     * When the App container is re-rendered, this hoc is re-mounted. Ensure there's only one keydown listener applied.
     */
    document.addEventListener('keydown', scannerListener);

    return () => {
      document.removeEventListener('keydown', scannerListener);
    };
  }, []);
  return (
    <>
      <EventsModal
        modalType={ModalTypes.ProductScanFound}
        {...modalData}
        hideModal={handleHideModal}
        isOpen={isOpen}
        withButton={false}
      />
      {children}
    </>
  );
};

export default ScannerHOC;
