import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';

/**
 * @returns the whole locationLog state
 */
const selectLocationLog = (state: RootState) => state.users.locationLog;

/**
 * @returns {boolean} loading status of locationLog state
 */
export const selectLocationLogLoading = createSelector([selectLocationLog], state => state.isLoading);

/**
 * @returns {boolean} check if locationLog data is already loaded
 */
export const selectLocationLogDataLoaded = createSelector([selectLocationLog], state => state.isDataLoaded);

/**
 * @returns locationLog data
 */
export const selectLocationLogData = createSelector([selectLocationLog], state => state.data);

/**
 * @returns {string} locationLog error message
 */
export const selectLocationLogError = createSelector([selectLocationLog], state => state.error);
