import { FC } from 'react';

import { ReactComponent as CheckedIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_item_checked.svg';
import { ReactComponent as NotCheckedIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_item_not_checked.svg';
import * as S from '@components/web/src/atoms/Filters/SublistItem/styles';
import { TSublistItemProps } from '@components/web/src/atoms/Filters/types';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

interface Props {
  item: TSublistItemProps;
  isActive?: boolean;
  onItemClick?: (item: TSublistItemProps) => void;
}

const SublistItem: FC<Props> = ({ isActive = false, onItemClick, item }) => {
  const text = item.name || item.value;
  return (
    <S.SublistItemContainer
      aria-hidden
      $isActive={isActive}
      align="center"
      justify="space-between"
      onClick={() => onItemClick(item)}
    >
      <Text
        color={STATIC_COLORS.base.black}
        size="subtitle2"
        text={text}
        textTransform="capitalize"
        weight={isActive ? 'semibold' : 'normal'}
      />
      {isActive ? <CheckedIcon /> : <NotCheckedIcon className="unchecked" />}
    </S.SublistItemContainer>
  );
};

export default SublistItem;
