/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import { useMergeRefs } from '@floating-ui/react';
import {
  HTMLProps,
  ReactNode,
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useContext,
  useMemo,
} from 'react';

import { ContextType, TooltipOptions, useTooltip } from '@components/web/src/atoms/Tooltips/useTooltip';

interface TooltipContextType extends ContextType {
  isVariantTasteId?: boolean;
}

const TooltipContext = createContext<TooltipContextType>(null);

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context === null) {
    throw new Error('Tooltip must be wrapped in <Tooltip />');
  }

  return context;
};

export const Tooltip = ({
  children,
  isVariantTasteId = false,
  isShowOnHover = true,
  ...options
}: { children: ReactNode; isVariantTasteId?: boolean; isShowOnHover?: boolean } & TooltipOptions) => {
  const tooltip = useTooltip({ ...options, isShowOnHover });

  const contextValue = useMemo(() => ({ ...tooltip, isVariantTasteId }), [tooltip, isVariantTasteId]);

  return <TooltipContext.Provider value={contextValue}>{children}</TooltipContext.Provider>;
};

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement> & { asChild?: boolean }>(
  function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
    const context = useTooltipContext();
    const childRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childRef]);

    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed',
        }),
      );
    }

    return (
      <div ref={ref} data-state={context.open ? 'open' : 'closed'} {...context.getReferenceProps(props)}>
        {children}
      </div>
    );
  },
);

export const TooltipContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & { color: string }>(
  function TooltipContent({ style, color, ...props }, propRef) {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    const { x: arrowX, y: arrowY } = context.middlewareData.arrow ?? {};

    const staticSide =
      {
        bottom: 'top',
        left: 'right',
        right: 'left',
        top: 'bottom',
      }[context.placement.split('-')[0]] ?? '';

    if (!context.open) return null;

    // this is the offset that prevents the Tooltip arrow overflowing outside the tooltip textbox
    const toolTipArrowOffset = {
      'top-start': -10,
      'top-end': -3,
      'bottom-start': -6,
      'bottom-end': 7,
      'right-start': -2,
      'right-end': 2,
      'left-start': -2,
      'left-end': 2,
      none: 0,
    };

    const offsetSize = context.isVariantTasteId
      ? 'none'
      : Object.keys(toolTipArrowOffset).find(key => context.placement.includes(key)) || 'none';

    return (
      <div
        ref={ref}
        style={{
          left: context.x ?? 0,
          position: context.strategy,
          top: context.y ?? 0,
          visibility: context.x == null ? 'hidden' : 'visible',
          ...style,
        }}
        {...context.getFloatingProps(props)}
      >
        {props.children}
        <div
          ref={context.arrowRef}
          style={{
            background: color,
            height: '14px',
            top: arrowY != null ? `${arrowY + toolTipArrowOffset[offsetSize]}px` : '',
            left: arrowX != null ? `${arrowX + toolTipArrowOffset[offsetSize]}px` : '',
            position: 'absolute',
            [staticSide]: '-6px',
            transform: 'rotate(45deg)',
            width: '14px',
          }}
        />
      </div>
    );
  },
);
