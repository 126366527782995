import { FC } from 'react';
import QRCode from 'react-qr-code';

import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { parseProductInstance } from '@lib/core/products/utils';
import { IIdentity } from '@lib/core/products/utils/parseProductIdentitiesForKiosk';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT, MixpanelPositionContext } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PRODUCT_CATEGORY_BEER, PRODUCT_CATEGORY_COFFEE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';
import { useDisclosure } from '@lib/tools/views/hooks/useDisclosure';

import missingBeer from '@components/web/src/assets/icons/largeProductPage/missingBeer.png';
import missingCoffee from '@components/web/src/assets/icons/largeProductPage/missingCoffee.png';
import missingWine from '@components/web/src/assets/icons/largeProductPage/missingWine.png';
import qrBackground from '@components/web/src/assets/icons/largeProductPage/qrBackground.svg';
import qrImage from '@components/web/src/assets/icons/largeProductPage/qrFind.png';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import KioskCirclesBackground from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import { IRecipe } from '@components/web/src/organisms/Cards/ChefRecommendsCard/ChefRecommendsCard';
import ReadMoreCard, { IReadMoreCardProps } from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';
import LargeProductDetails from '@components/web/src/organisms/ProductDetails/Kiosk/LargeProductDetails';
import ChefRecommendedPairingsModal from '@components/web/src/templates/Modals/ChefRecommendedPairingsModal/ChefRecommendedPairingsModal';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import * as S from '@components/web/src/templates/ProductPage/Kiosk/styles';

interface IProps {
  locale: string;
  productInstanceData: TProductInstance;
  productCategory: TProductCategory;
  perfectForData: string;
  recipesData: IRecipe[];
  productIdentitiesData: IIdentity[];
  sustainabilityData: IReadMoreCardProps[];
  mobileKioskQRUrl: string;
  isShowPromoLabel: boolean;
  isEnableLocationMapAddon: boolean;
  isShowProductLocationAddon: boolean;
  isEnableVusionAddon: boolean;
  shouldHideWishlist: boolean;
  isWishlistProductListLoading?: boolean;
  isProductInstanceInWishlist: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  isUserHasAnyCharacter?: boolean;
  mixpanelPositionContext?: MixpanelPositionContext;
  navigateToQuizSelectionPage: () => void;
  handleUpdateWishlistProductList?: (productId: string) => void;
  onBackButtonClick: () => void;
  navigateToCatalog: () => void;
}

const ProductPage: FC<IProps> = ({
  locale,
  productInstanceData,
  productCategory,
  recipesData,
  mobileKioskQRUrl,
  isShowPromoLabel,
  isEnableLocationMapAddon,
  isShowProductLocationAddon,
  isEnableVusionAddon,
  shouldHideWishlist,
  isProductInstanceInWishlist,
  isWishlistProductListLoading,
  isUserHasAnyCharacter,
  productIdentitiesData,
  mixpanelPositionContext = MP_POSITION_CONTEXT.PRODUCT_PAGE,
  navigateToQuizSelectionPage,
  handleUpdateWishlistProductList,
  onBackButtonClick,
  navigateToCatalog,
}) => {
  const { publishedTerms } = LocaleUtils;

  const { productCategoriesPlural, productDetails: productDetailsMessages } = localeCommon;
  const { productPage } = localeKiosk;
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory].id];

  const { productDescription, productCharacterTechnicalCaption } = parseProductInstance({
    locale,
    productInstanceData,
  });

  const missingImage =
    {
      [PRODUCT_CATEGORY_BEER]: missingBeer,
      [PRODUCT_CATEGORY_COFFEE]: missingCoffee,
    }[productCategory] || missingWine;

  const [isChefRecommendedPairingsModalOpen, { open: openModal, close: closeModal }] = useDisclosure();

  return (
    <S.KioskProductPageContainer>
      <KioskCirclesBackground />
      <S.StyledBackButton handleClick={onBackButtonClick} />
      <LargeProductDetails
        handleUpdateWishlistProductList={handleUpdateWishlistProductList}
        isEnableLocationMapAddon={isEnableLocationMapAddon}
        isEnableVusionAddon={isEnableVusionAddon}
        isProductInstanceInWishlist={isProductInstanceInWishlist}
        isShowProductLocationAddon={isShowProductLocationAddon}
        isShowPromoLabel={isShowPromoLabel}
        isUserHasAnyCharacter={isUserHasAnyCharacter}
        isWishlistProductListLoading={isWishlistProductListLoading}
        locale={locale}
        navigateToQuizSelectionPage={navigateToQuizSelectionPage}
        productIdentitiesData={productIdentitiesData}
        productInstanceData={productInstanceData}
        shouldHideWishlist={shouldHideWishlist}
      />
      <S.ProductContent>
        <S.MoreAboutProduct>
          {productDescription && (
            <ReadMoreCard
              isLargeVariant
              isTransparentVariant
              cardTitle={productDetailsMessages.descriptionTitle}
              cardTitleColor={STATIC_COLORS.green['600']}
              description={productDescription}
              minimumCharacters={190}
              handleClick={() =>
                MixpanelTracker.events.productStoryClick(
                  productInstanceData,
                  isProductInstanceInWishlist,
                  mixpanelPositionContext,
                )
              }
            />
          )}
          {mobileKioskQRUrl && (
            <S.RedirectQrSection>
              <S.QrWrapper $qrBackground={qrBackground}>
                <S.QrBackgroundImage alt="qrBackground" src={qrBackground} />
                <QRCode className="qr-code" fgColor="black" size={150} value={mobileKioskQRUrl} />
              </S.QrWrapper>
              <S.QrText
                color={STATIC_COLORS.base.black}
                size="h5"
                text={productDetailsMessages.continueOnYourPhone}
                weight="bold"
              />
              <Image alt="image" height="159px" src={qrImage} width="151px" />
            </S.RedirectQrSection>
          )}
        </S.MoreAboutProduct>
        <S.ChefRecommendedPairingsWrapper>
          <S.ChefRecommendedPairingsImage alt="Chef Recommend" borderRadius="10px" src={recipesData?.[0].image} />
          <S.ChefRecommendedPairingsTextWrapper>
            <Text
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="h5"
              text={productPage.perfectPairing}
              weight="semibold"
            />
            <Text color={STATIC_COLORS.base.black} size="h6" text={recipesData?.[0].title} />
          </S.ChefRecommendedPairingsTextWrapper>
          <S.ChefRecommendedPairingsCta
            onClick={() => {
              openModal();
              MixpanelTracker.events.discoverMorePairingsClick(productInstanceData);
            }}
          >
            <S.ChefRecommendedPairingsCtaText
              color={STATIC_COLORS.teal[600]}
              size="subtitle1"
              text={productPage.pairingCta}
              textDecoration="underline"
              weight="medium"
            />
          </S.ChefRecommendedPairingsCta>
        </S.ChefRecommendedPairingsWrapper>
        <S.ProductTechnicalDescriptionWrapper>
          <S.TechnicalDescriptionImageOne alt="bottle" height="200px" src={missingImage} width="200px" />
          <S.TechnicalDescriptionContent>
            <Text
              color={STATIC_COLORS.base.black}
              localeOptions={{ productTechnicalDescription: productCharacterTechnicalCaption }}
              size="h5"
              text={productPage.lookingFor}
              weight="semibold"
            />
            <Button
              fontSize="subtitle2"
              localeOptions={{ productCategory, productCategoryPluralText }}
              size="lg"
              text={productPage.seeSimilarBtn}
              textWeight="medium"
              variant={VH_VARIANTS.PRIMARY}
              handleClick={() => {
                MixpanelTracker.events.seeSimilarProductsClick(productInstanceData);
                navigateToCatalog();
              }}
            />
          </S.TechnicalDescriptionContent>
          <S.TechnicalDescriptionImageTwo alt="bottle" height="200px" src={missingImage} width="200px" />
        </S.ProductTechnicalDescriptionWrapper>
        {isChefRecommendedPairingsModalOpen && (
          <ChefRecommendedPairingsModal
            isChefRecommendedPairingsModalOpen={isChefRecommendedPairingsModalOpen}
            recipesData={recipesData}
            onClose={closeModal}
          />
        )}
      </S.ProductContent>
    </S.KioskProductPageContainer>
  );
};

export default ProductPage;
