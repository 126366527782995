import { retailerServiceTermsCategories } from '@lib/core/serviceTerms/consts';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { useUser } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_THIRD_PARTY_OPTION_SELECTED } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { actionAddToastMessage } from '@lib/tools/toastMessage/slices';
import { ToastMessageTypes } from '@lib/tools/toastMessage/utils/consts';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const useThirdParty = () => {
  const dispatch = useTypedDispatch();
  const { isUserAuthenticated } = useUser();
  const { ToastMessages } = localeCommon;
  const { titleText, acceptBtnText, rejectBtnText } = localeCommon.ThirdPartyNewsletter;
  const {
    isTermsInitialDataLoading,
    serviceTermsListUpdateIsLoading,
    serviceTermsByCategory: { [retailerServiceTermsCategories.thirdParty]: thirdPartyData },
    updateServiceTerms,
  } = useServiceTerms();

  const isRetailerSupportsThirdPartyOption = !!thirdPartyData;
  const descriptionText = thirdPartyData?.text || '';

  const handleThirdPartyAcceptance = (isAccepted: boolean) =>
    updateServiceTerms([{ category: retailerServiceTermsCategories.thirdParty, is_selected: isAccepted }])
      .then(() => {
        const optionSelected = isAccepted ? MP_THIRD_PARTY_OPTION_SELECTED.YES : MP_THIRD_PARTY_OPTION_SELECTED.NO;

        MixpanelTracker.events.thirdPartyChoiceClick({ optionSelected });

        dispatch(
          actionAddToastMessage({
            title: isAccepted ? ToastMessages.thirdPartyAgreementAccepted : ToastMessages.thirdPartyAgreementRejected,
            type: ToastMessageTypes.success,
          }),
        );
      })
      .catch(() => {
        dispatch(
          actionAddToastMessage({
            message: ToastMessages.userDataFailed,
            type: ToastMessageTypes.error,
          }),
        );
      });

  return {
    handleThirdPartyAcceptance,
    isRetailerSupportsThirdPartyOption,
    isShowThirdPartyAcceptance:
      isUserAuthenticated &&
      isRetailerSupportsThirdPartyOption &&
      thirdPartyData.is_selected === undefined &&
      !isTermsInitialDataLoading,
    isTermsInitialDataLoading,
    serviceTermsListUpdateIsLoading,
    thirdPartyData,
    thirdPartyNewsletterTexts: {
      acceptBtnText,
      descriptionText,
      rejectBtnText,
      titleText,
    },
  };
};
