import { useSelector } from 'react-redux';

import {
  selectLocationLogData,
  selectLocationLogDataLoaded,
  selectLocationLogLoading,
} from '@lib/core/users/selectors/locationLog';

export const useLocationLog = () => {
  const locationLogData = useSelector(selectLocationLogData);
  const isLocationLogLoading = useSelector(selectLocationLogLoading);
  const isLocationLogDataLoaded = useSelector(selectLocationLogDataLoaded);

  return {
    isLocationLogDataLoaded,
    isLocationLogLoading,
    locationLogData,
  };
};
