import { FC, useState } from 'react';

import { TProductLocationPromotion } from '@lib/core/products/types/productInstance';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { PROMOTION_BADGE_FIDELITY, PROMOTION_BADGE_PROMOTION } from '@lib/tools/shared/helpers/consts';

import DefaultPromotionIcon from '@components/web/src/assets/icons/promo_placeholder.png';
import TooltipComponent from '@components/web/src/atoms/Tooltips/Tooltip';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/PromotionBadges/styles';

interface IProps {
  promotionsData: TProductLocationPromotion[];
  isLargeVariant?: boolean;
}

export const PromotionBadges: FC<IProps> = ({ promotionsData, isLargeVariant = false }) => {
  const { defaultBadgePromotionImage, defaultBadgeFidelityImage } = localeCommon.promotionBadges;

  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);

  const minimumTooltipCharacters = (description?: string) => description?.slice(0, 60);

  const renderDefaultImage = (typeSlug: string) => {
    switch (typeSlug) {
      case PROMOTION_BADGE_PROMOTION:
        return (
          <S.DefaultPromotionIcon $color="#cf0e02" $isLargeVariant={isLargeVariant}>
            <Text color={STATIC_COLORS.base.white} size="body2" text={defaultBadgePromotionImage} weight="medium" />
          </S.DefaultPromotionIcon>
        );
      case PROMOTION_BADGE_FIDELITY:
        return (
          <S.DefaultPromotionIcon $color="#ffea2d" $isLargeVariant={isLargeVariant}>
            <Text color={STATIC_COLORS.base.black} size="body2" text={defaultBadgeFidelityImage} weight="medium" />
          </S.DefaultPromotionIcon>
        );
      default:
        return <Image alt="promo-icon" height="100%" objectFit="contain" src={DefaultPromotionIcon} />;
    }
  };

  return (
    <S.PromotionBadgesContainer>
      {promotionsData.map(promotion => {
        const { identifier, description, slug, typeSlug, image } = promotion;
        return (
          <S.PromotionIconWrapper
            key={identifier}
            $isLargeVariant={isLargeVariant}
            id="PromotionIcon"
            onClick={e => {
              e.stopPropagation();
              if (description) {
                setActiveTooltip(prevState => (prevState === identifier ? null : identifier));

                MixpanelTracker.events.promotionDescriptionClick({
                  promotionDescription: description,
                  promotionId: identifier,
                  promotionSlug: slug,
                  promotionTypeSlug: typeSlug,
                });
              }
            }}
          >
            <TooltipComponent
              isAlwaysOpen={activeTooltip === identifier}
              isShowOnHover={false}
              placement="top-start"
              title={minimumTooltipCharacters(description)}
              variant="green"
              trigger={
                image ? (
                  <Image alt="promo-icon" height="100%" objectFit="contain" src={image} />
                ) : (
                  renderDefaultImage(typeSlug)
                )
              }
            />
          </S.PromotionIconWrapper>
        );
      })}
    </S.PromotionBadgesContainer>
  );
};
