import { FC, Fragment, useState } from 'react';

import { TProductInstance } from '@lib/core/products/types';
import { parseFindProduct, parseProductInstance } from '@lib/core/products/utils';
import { IIdentity } from '@lib/core/products/utils/parseProductIdentitiesForKiosk';
import { promotionsFilter } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT, MixpanelPositionContext } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  PROMOTION_BADGE_FIDELITY,
  PROMOTION_BADGE_OTHER,
  PROMOTION_BADGE_PROMOTION,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';
import { parseTasteMatchLevel } from '@lib/tools/tasteMatch';
import { useAddons } from '@lib/tools/views/hooks';

import Button from '@components/web/src/atoms/Buttons/Button';
import PriceRange from '@components/web/src/atoms/PriceRange/PriceRange';
import TasteMatchButton from '@components/web/src/atoms/TasteMatchButton/TasteMatchButton';
import TooltipComponent from '@components/web/src/atoms/Tooltips/Tooltip';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/ProductDetails/Kiosk/styles';
import { PromotionBadges } from '@components/web/src/organisms/PromotionBadges/PromotionBadges';
import FindProductPopup from '@components/web/src/shared/FindProductPopup/FindProductPopup';

interface IProps {
  locale: string;
  productInstanceData: TProductInstance;
  isShowPromoLabel?: boolean;
  isEnableLocationMapAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  isEnableVusionAddon?: boolean;
  isProductInstanceInWishlist?: boolean;
  isWishlistProductListLoading?: boolean;
  shouldHideWishlist: boolean;
  isUserHasAnyCharacter: boolean;
  mixpanelPositionContext?: MixpanelPositionContext;
  productIdentitiesData?: IIdentity[];
  navigateToQuizSelectionPage: () => void;
  handleUpdateWishlistProductList?: (productId: string, productName?: string) => void;
}

const LargeProductDetails: FC<IProps> = ({
  locale,
  productInstanceData,
  isShowPromoLabel,
  isEnableLocationMapAddon,
  isShowProductLocationAddon,
  isEnableVusionAddon,
  isWishlistProductListLoading,
  isProductInstanceInWishlist,
  isUserHasAnyCharacter,
  mixpanelPositionContext = MP_POSITION_CONTEXT.PRODUCT_PAGE,
  shouldHideWishlist,
  productIdentitiesData,
  handleUpdateWishlistProductList,
  navigateToQuizSelectionPage,
}) => {
  const { productDetails: productDetailsMessages, commonMessages } = localeCommon;

  const { isFindProductButtonEnable, productLocationDescription, productLocationMap, productEcommerceId } =
    parseFindProduct({
      isEnableLocationMapAddon,
      isEnableVusionAddon,
      isShowProductLocationAddon,
      productInstanceData,
    });

  const {
    productId,
    productCategory,
    productCharacterName,
    productCharacterTechnicalCaption,
    productName,
    productImage,
    productTags,
    productFormatSize,
    productCurrencySymbol,
    productProducerName,
    productOriginalPrice,
    productDiscountPrice,
    showedProductOriginalPrice,
    showedProductDiscountPrice,
    promotions,
    productTasteMatchValue,
  } = parseProductInstance({
    locale,
    productInstanceData,
  });
  const { isPriceRangeAddon } = useAddons();
  const [isTooltipVisible, setTooltipVisible] = useState(true);

  const promotionBadges = promotionsFilter(promotions, [
    PROMOTION_BADGE_FIDELITY,
    PROMOTION_BADGE_PROMOTION,
    PROMOTION_BADGE_OTHER,
  ]);

  return (
    <S.LargeProductDetailsContainer>
      <S.LargeProductImageContainer>
        {isShowPromoLabel && (
          <S.LargeTopPromoIcon>
            <Text color={STATIC_COLORS.base.white} size="subtitle1" text={commonMessages.promo} weight="semibold" />
          </S.LargeTopPromoIcon>
        )}
        <S.LargeProductImageWrapper>
          <Image alt="product" height="85%" objectFit="contain" src={productImage} />
        </S.LargeProductImageWrapper>
        <S.WishListCtaWrapper align="center" direction="column" gap={16}>
          {!shouldHideWishlist && (
            <Wishlist
              isLargeSize
              isItemInWishlist={isProductInstanceInWishlist}
              isItemListLoading={isWishlistProductListLoading}
              handleUpdateWishlist={() => {
                handleUpdateWishlistProductList(productId, productName);
                MixpanelTracker.events.productBookmark(
                  productInstanceData,
                  isProductInstanceInWishlist,
                  null,
                  mixpanelPositionContext,
                );
              }}
            />
          )}
        </S.WishListCtaWrapper>
      </S.LargeProductImageContainer>
      <S.LargeProductContentContainer>
        <S.FirstSection>
          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            linesLimit={3}
            size="h3"
            text={productName}
            weight="semibold"
          />
          {productProducerName && (
            <Text color={STATIC_COLORS.base.black} size="h6" text={productProducerName} weight="medium" />
          )}
          <S.LargeTagsWrapper>
            {productTags?.map(tag => (
              <Image key={tag.tagName} alt={tag.tagName} height="44px" src={tag.icon} width="44px" />
            ))}
          </S.LargeTagsWrapper>
        </S.FirstSection>
        <S.CharacterBlock>
          <Text color={STATIC_COLORS.base.black} size="subtitle2" text={`#${productCharacterName}`} weight="semibold" />{' '}
          <Text color={STATIC_COLORS.base.black} size="subtitle2" text={`• ${productCharacterTechnicalCaption}`} />
        </S.CharacterBlock>
        <TooltipComponent
          isAlwaysOpen={isTooltipVisible}
          placement="top-end"
          title={productDetailsMessages.tasteMatchTooltip}
          trigger={
            <TasteMatchButton
              hideTooltip={() => setTooltipVisible(false)}
              navigateToQuizSelectionPage={navigateToQuizSelectionPage}
              productInstanceData={productInstanceData}
              tasteMatchLevel={parseTasteMatchLevel(productTasteMatchValue, isUserHasAnyCharacter)}
              tasteMatchValue={productTasteMatchValue}
            />
          }
        />
        <S.LargePriceAndFormatWrapper>
          {isPriceRangeAddon ? (
            <PriceRange
              isLargeFont
              currencySymbolValue={productCurrencySymbol}
              originalPrice={productOriginalPrice}
              productCategory={productCategory}
            />
          ) : (
            <S.PriceBlock>
              {productDiscountPrice ? (
                <>
                  <S.LargePriceDashText color={STATIC_COLORS.gray[700]} size="h5" text={showedProductOriginalPrice} />
                  <Text
                    color={STATIC_COLORS.base.black}
                    size="h4"
                    text={showedProductDiscountPrice}
                    weight="semibold"
                  />
                  {productFormatSize && (
                    <Text color={STATIC_COLORS.gray[700]} size="h6" text={`/ ${productFormatSize}`} />
                  )}
                </>
              ) : (
                <>
                  <Text
                    color={STATIC_COLORS.base.black}
                    size="h4"
                    text={showedProductOriginalPrice}
                    weight="semibold"
                  />{' '}
                  {productFormatSize && (
                    <Text color={STATIC_COLORS.gray[700]} size="h6" text={`/ ${productFormatSize}`} />
                  )}
                </>
              )}
            </S.PriceBlock>
          )}
        </S.LargePriceAndFormatWrapper>
        <S.IdentitiesWrapper>
          {productIdentitiesData.map(({ icon, title, description }, index) => (
            <Fragment key={index}>
              <S.IdentityImage alt="icon" src={icon} />
              <S.IdentityTitle>
                <Text size="subtitle2" text={title} />:
              </S.IdentityTitle>

              <Text size="subtitle2" text={description} weight="semibold" />
            </Fragment>
          ))}
        </S.IdentitiesWrapper>
        {!!promotionBadges?.length && <PromotionBadges isLargeVariant promotionsData={promotionBadges} />}
        <S.LargeCtaWrapper>
          {isFindProductButtonEnable && (
            <FindProductPopup
              isEnableLocationMapAddon={isEnableLocationMapAddon}
              isEnableVusionAddon={isEnableVusionAddon}
              productEcommerceId={productEcommerceId}
              productLocationDescription={productLocationDescription}
              productLocationMap={productLocationMap}
            >
              <Button
                fontSize="subtitle2"
                size="lg"
                text={productDetailsMessages.findItOnShelf}
                type="button"
                variant={VH_VARIANTS.PRIMARY}
                onClick={() =>
                  MixpanelTracker.events.findMe(
                    productInstanceData,
                    isProductInstanceInWishlist,
                    null,
                    mixpanelPositionContext,
                  )
                }
              />
            </FindProductPopup>
          )}
        </S.LargeCtaWrapper>
      </S.LargeProductContentContainer>
    </S.LargeProductDetailsContainer>
  );
};

export default LargeProductDetails;
