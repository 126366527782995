import { Range as LibRange, createSliderWithTooltip } from 'rc-slider';

import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import { setPriceRangeValues } from '@lib/tools/filterManager/slices/rangeFilterSlice';
import { currencies } from '@lib/tools/locale/utils/consts';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

import * as S from '@components/web/src/atoms/Filters/PriceRangeFilter/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

const RangeWithTooltip = createSliderWithTooltip(LibRange);

const Range = ({ min, max, values, setValues }) => {
  const handleStyles = [
    {
      borderColor: 'transparent',
      boxShadow: 'unset',
      marginTop: -10,
    },
    {
      borderColor: 'transparent',
      boxShadow: 'unset',
      marginTop: -10,
    },
  ];

  const railStyle = { backgroundColor: '#EAE9E3', borderRadius: '100vmax', height: 8 };

  return (
    <RangeWithTooltip
      draggableTrack
      allowCross={false}
      className="range-slider-vinhood"
      dotStyle={{ display: 'none' }}
      handleStyle={handleStyles}
      max={max}
      min={min}
      pushable={1}
      railStyle={railStyle}
      step={1}
      trackStyle={[{ borderRadius: 0, cursor: 'grab', height: 8 }]}
      value={values}
      tipProps={{
        prefixCls: 'slider-tooltip',
        visible: false,
      }}
      onChange={setValues}
    />
  );
};

const PriceRangeFilter = () => {
  const dispatch = useTypedDispatch();
  const { userUpperPriceRangeValue, userLowerPriceRangeValue, appMaxPriceRangeValue, appMinPriceRangeValue } =
    useCatalogFilter();

  const setValues = e => {
    const [newMinValue, newMaxValue] = e;
    dispatch(
      setPriceRangeValues({
        isManual: true,
        userLowerPriceRangeValue: newMinValue,
        userUpperPriceRangeValue: newMaxValue,
      }),
    );
  };
  const priceText = `${userLowerPriceRangeValue} ${currencies.EUR} - ${userUpperPriceRangeValue} ${currencies.EUR}`;

  return (
    <S.PriceRangeFilterContainer isFullWidth direction="column" gap={16} justify="center">
      <Text color={STATIC_COLORS.base.black} size="subtitle2" text={priceText} textAlign="center" />
      <S.RangeWrapper align="center">
        <Range
          max={Math.round(appMaxPriceRangeValue)}
          min={Math.floor(appMinPriceRangeValue)}
          setValues={setValues}
          values={[userLowerPriceRangeValue, userUpperPriceRangeValue]}
        />
      </S.RangeWrapper>
    </S.PriceRangeFilterContainer>
  );
};

export default PriceRangeFilter;
