import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { locationLogApiUrlCreator } from '@lib/core/users/slices/urls';
import { ILocation } from '@lib/core/users/types';
import { parseError } from '@lib/tools/shared/helpers';

export interface ILocationLogState {
  data: ILocation[];
  error: string;
  isLoading: boolean;
  isDataLoaded: boolean;
}

const initialState: ILocationLogState = {
  data: [],
  error: '',
  isDataLoaded: false,
  isLoading: false,
};

export const actionGetLocationLog = createTypedAsyncThunk('actionGetLocationLog', async (_, { rejectWithValue }) => {
  try {
    const response = await request(locationLogApiUrlCreator(), { method: REQUEST_METHODS.GET });
    return { locationLog: response };
  } catch (error) {
    return rejectWithValue(parseError(error));
  }
});

export const actionUpdateLocationLog = createTypedAsyncThunk(
  'actionUpdateLocationLog',
  async (retailerLocationSlug: string, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const locationLog = state.users.locationLog.data;
      const isLocationInLog = locationLog.some(location => location?.retailerLocation?.slug === retailerLocationSlug);

      if (isLocationInLog) {
        return { location: null };
      }

      const response = await request(
        locationLogApiUrlCreator(),
        { method: REQUEST_METHODS.POST },
        { retailerLocation: retailerLocationSlug },
      );
      return { location: response };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

const locationLogSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(isAnyOf(actionGetLocationLog.pending, actionUpdateLocationLog.pending), state => {
      state.isDataLoaded = false;
      state.error = '';
      state.isLoading = true;
    });
    builder.addMatcher(isAnyOf(actionGetLocationLog.fulfilled), (state, action) => {
      state.data = action.payload.locationLog.results;
      state.isDataLoaded = true;
      state.isLoading = false;
    });
    builder.addMatcher(isAnyOf(actionUpdateLocationLog.fulfilled), (state, action) => {
      if (action.payload.location) {
        state.data.push(action.payload.location);
      }
      state.isLoading = false;
    });

    builder.addMatcher(
      isAnyOf(actionGetLocationLog.rejected, actionUpdateLocationLog.rejected),
      (state, action: any) => {
        state.isDataLoaded = true;
        state.error = action.payload || action.error.message || 'An error occurred';
        state.isLoading = false;
      },
    );
  },
  initialState,
  name: 'locationLog',
  reducers: {
    actionResetLocationLog: () => initialState,
  },
});

export const { actionResetLocationLog } = locationLogSlice.actions;
export default locationLogSlice.reducer;
