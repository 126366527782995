import styled from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { fontFamilies, generateTypographyStyles } from '@components/web/src/foundations/Text/Text.styles';
import { TFontFamilies, TStyleWeight, TTypographyVariants } from '@components/web/src/foundations/Text/types';

export const CommentModalContainer = styled(Flexbox)`
  border-radius: 16px;
  background-color: ${STATIC_COLORS.base.white};
`;

export const ImageWrapper = styled.div`
  min-width: 25px;
`;

export const CloseIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 9px;
  padding-left: 2px;
`;

export const CommentForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 16px;

  textarea::-webkit-scrollbar {
    width: 0;
  }
`;

export const CommentArea = styled.textarea<{
  $size?: TTypographyVariants;
  $weight?: TStyleWeight;
  $color?: string;
  $fontFamily?: TFontFamilies;
}>`
  display: flex;
  width: 100%;
  align-self: stretch;
  padding: 12px;
  min-height: 59px;
  max-height: 240px;
  border: 1px solid ${STATIC_COLORS.gray[500]};
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 6px;

  ${({ $size, $weight, $fontFamily, theme }) =>
    generateTypographyStyles($size, $fontFamily || fontFamilies.Montserrat, $weight, theme?.isKioskRootElementType)}
`;
